/**  http.js **/
// 导入封装好的axios实例
import request from './request'

const http = {
  get(url, params, headers) {
    const config = {
      method: 'get',
      url: url,
      headers: headers
    }
    if (params) config.params = params
    return request(config)
  },
  post(url, data, headers) {
    const config = {
      method: 'post',
      url: url,
      headers: headers
    }
    if (data) config.data = data
    return request(config)
  },
  postResponse(url, data, responseType) {
    const config = {
      method: 'post',
      url: url,
      responseType: responseType
    }
    if (data) config.data = data
    return request(config)
  },
  put(url, data, headers) {
    const config = {
      method: 'put',
      url: url,
      headers: headers
    }
    if (data) config.data = data
    return request(config)
  },
  delete(url, data, headers) {
    const config = {
      method: 'delete',
      url: url,
      headers: headers
    }
    if (data) config.data = data
    return request(config)
  }
}
// 导出
export default http
