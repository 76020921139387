import axios from 'axios'
import store from '@/store/index'
import { ElLoading } from 'element-plus'

/** **** 创建axios实例 ******/
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000, // 请求超时时间
  withCredentials: false,
  header: {
    'Content-Type': 'application/json; charset=UTF-8'
  }
})
let loading
/** **** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
  (config) => {
    if (process.env.VUE_APP_BASE_DEBUG) {
      console.debug('config ==>> ' + JSON.stringify(config))
    }
    const accessToken = store.getters['tenant/getAccessToken']
    const tokenType = store.getters['tenant/getTokenType']
    if (accessToken && tokenType) {
      config.headers.Authorization = tokenType + ' ' + accessToken
    }
    loading = ElLoading.service({ lock: true, text: '数据加载中...' })
    return config
  },
  (error) => {
    // 请求错误处理
    loading.close()
    console.info(error)
  }
)

/** **** response拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  (response) => {
    // 成功请求到数据
    if (process.env.VUE_APP_BASE_DEBUG) {
      console.debug('response ==>> ' + JSON.stringify(response))
    }
    const data = response.data
    loading.close()
    if (data.code === 401) {
      // 登录失效 必须跳转到登录页
      store.dispatch('tenant/syncClearToken')
    }
    return Promise.resolve(data)
  },
  (error) => {
    // 响应错误处理
    loading.close()
    return Promise.reject(error)
  }
)

// axios实例暴露
export default service
